<template>
  <div>
    <!-- 列表 -->
    <div class="list">

      <!-- 列表项目 -->
      <div class="list-item flex-column-center-center">
        <div class="status-bar flex-row-between-center">
          <span :class="(data.statusClass + ' flex-center-center ') + (['Signup', 'End'].includes(data.status) ? 'two-btn' : 'one-btn')">{{data.statusLabel}}</span>
          <span v-if="data.status === 'Signup'" class="cancel-btn two-btn flex-center-center" @click="handleCancelSignup">取消报名</span>
          <span v-if="data.status === 'End'" class="evaluate-btn two-btn flex-center-center" @click="handleEvaluate">评价</span>
        </div>
        <qrcode-vue v-if="data.status === 'Signup'" class="qr-code" :value="data.qrCode" :size="qrCodeSize" level="H"></qrcode-vue>
      </div>

      <!-- 列表项目 -->
      <div class="list-item" @click="handleSwitchPage">
        <!-- 图片 -->
        <img v-if="data.activity.mainPicture !== ''" :src="data.activity.mainPicture" :onerror="require('@/assets/images/img_fail.png')" />
        <!-- 内容块 -->
        <div class="body">
          <!-- 标题 -->
          <div class="title-line flex-row-between-center">
            <span class="title one-line">{{data.activity.name}}</span>
          </div>
          <!-- 内容 -->
          <div class="content">
            <div>活动时间：{{data.activity.activityTime}}</div>
            <div v-if="data.status === 'Cancel'">取消时间：{{data.canceledAt}}</div>
            <div v-if="data.signupAt">报名时间：{{data.signupAt}}</div>
            <div v-if="data.signinAt">签到时间：{{data.signinAt}}</div>
            <div>活动费用：{{data.activity.isFree ? "免费" : data.activity.cost + "元" }}</div>
            <div>报名姓名：{{data.user.name}}</div>
            <div v-if="data.isMember">所在企业：{{data.member.companyName}}</div>
            <div v-else>所属单位：{{data.member.companyName}}</div>
            <div>联系电话：{{data.user.phoneNumber}}</div>
          </div>
        </div>
      </div>

      <!-- 列表项目 -->
      <div class="list-item" v-if="isEvaluated" @click="handleSwitchPage">
        <!-- 内容块 -->
        <div class="body">
          <!-- 标题 -->
          <div class="title-line flex-row-between-center">
            <span class="title one-line">活动评价</span>
          </div>
          <!-- 内容 -->
          <div class="content">
            <div>评价时间：{{evaluationData.createdAt}}</div>
            <div class="evaluation-content">{{evaluationData.content}}</div>
            <!-- 图片块 -->
            <div class="image-block flex-row-default-center">
              <div class="show-image" v-for="(item, index) in evaluationData.pictures" :key="index">
                <img class="image" :src="item" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { Toast, MessageBox } from 'mint-ui'

export default {
  name: 'UserActivityDetail',

  data () {
    return {
      activitySignupID: '',
      data: {
        activity: {},
        member: {},
        user: {}
      },
      // 是否评价
      isEvaluated: false,
      evaluationData: {},
      qrCodeSize: 300
    }
  },

  components: { QrcodeVue },

  created () {
    this.activitySignupID = this.$route.query.id
    this.qrCodeSize = Math.min(this.getWindowWidth() - 30 - 40)
  },

  mounted () {
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    to.meta.keepAlive = to.path === '/user/activity-list'
    next()
  },

  methods: {
    // 获取数据
    getData () {
      this.api.getActivitySignup({ id: this.activitySignupID }).then(res => {
        const data = res.data
        switch (data.status) {
          case 'Signup':
            data.statusClass = 'signup'
            data.statusLabel = '待签到'
            break
          case 'Signin':
            data.statusClass = 'signin'
            data.statusLabel = '已签到'
            break
          case 'Cancel':
            data.statusClass = 'cancel'
            data.statusLabel = '已取消'
            break
          case 'NotSignin':
            data.statusClass = 'not-signin'
            data.statusLabel = '未签到'
            break
          case 'End': case 'Evaluation':
            data.statusClass = 'end'
            data.statusLabel = '已结束'
            break
        }
        data.qrCode = `${this.$store.state.baseURL}/activity-signin/?scanner=manager&id=${data.activitySignupID}`
        this.data = data
        // 如果已经评价就获取评价内容
        if (data.status === 'Evaluation') {
          this.isEvaluated = true
          this.getEvaluationData()
        }
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      })
    },
    // 获取评价数据
    getEvaluationData () {
      this.api.getActivityEvaluation({ id: this.data.activityID }).then(res => {
        this.evaluationData = res.data
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getEvaluationData() })
      })
    },
    // 切换页面
    handleSwitchPage () {
      this.$router.push({ path: '/activity-detail', query: { id: this.data.activityID } })
    },
    // 可视窗口宽度
    getWindowWidth () {
      return document.documentElement.clientWidth
    },
    // 取消报名
    handleCancelSignup () {
      MessageBox.confirm('是否确认取消报名？').then(() => {
        this.cancelSignup()
      }).catch(() => {})
    },
    // 确定取消报名
    cancelSignup () {
      this.api.cancelSignupActivity({ id: this.data.activityID }).then(res => {
        Toast(res.msg || '取消报名成功')
        this.data.status = 'Cancel'
        this.data.statusClass = 'cancel'
        this.data.statusLabel = '已取消'
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.cancelSignup() })
      })
    },
    // 活动评价
    handleEvaluate () {
      this.$router.push({ path: '/user/activity-evaluation', query: { id: this.data.activityID } })
    }
  }
}
</script>

<style scoped>
.list {
  margin: 15px;
}
.list-item {
  border: 1px solid #dddddd;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #eeeeee;
}
.list-item + .list-item {
  margin-top: 10px;
}

.status-bar {
  width: 100%;
  padding: 15px 20px 15px 20px;
  font-size: 18px;
}
.one-btn {
  width: 100%;
  height: 35px;
  border-radius: 3px;
  color: #fff;
}
.two-btn {
  width: 48%;
  height: 35px;
  border-radius: 3px;
  color: #fff;
}
.signup {
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.signin {
  color: #42b983;
  border: 1px solid #42b983;
}
.cancel {
  color: #aaa;
  border: 1px solid #aaa;
}
.end {
  color: #dd6975;
  border: 1px solid #dd6975;
}
.not-signin {
  color: #aaa;
  border: 1px solid #aaa;
}

.cancel-btn {
  background: #aaa;
}
.evaluate-btn {
  background: #dd6975;
}

.qr-code {
  margin: 0 20px 20px 20px;
}

.list-item > img {
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.body {
  padding: 5px 15px;
  font-size: 15px;
  color: var(--normal-font-color);
}

.title-line {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.title {
  width: 80%;
}

.content {
  padding: 10px;
  font-size: 13px;
  color: #999;
}
.content > div + div {
  margin-top: 5px;
}
.evaluation-content {
  padding-top: 8px;
  font-size: 15px;
  color: #333;
}
</style>
